import { notDesktop, styled } from "@obvio/app";
import { RichText } from "@obvio/template";
import { Stack, GridItem, Grid } from "@obvio/ui";

import { CtaLink } from "@/components/CtaLink";

import type { ReactElement } from "react";

const Item = styled(GridItem)`
  min-height: 10rem;

  > div {
    height: 100%;
  }
`;

const RichTextWrap = styled.div`
  flex: 1;
`;

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

type Tile = {
  data: {
    ctaText?: string;
    url?: string;
    text1?: string;
  };
  title: string;
};

type TilesProps = {
  tiles: Tile[];
};
export function TilesCta({ tiles }: TilesProps): ReactElement {
  return (
    <Wrap templateColumns="repeat(3,  auto)" gap="extraLarge">
      {tiles.map((tile) => (
        <Item key={tile.title} noCenter>
          <Stack kind="vertical">
            <RichTextWrap>
              <RichText value={tile.data.text1 ?? ""} />
            </RichTextWrap>
            {tile.data.ctaText && tile.data.url ? (
              <CtaLink href={tile.data.url} text={tile.data.ctaText} as="h3" />
            ) : null}
          </Stack>
        </Item>
      ))}
    </Wrap>
  );
}
