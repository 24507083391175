import { notDesktop, styled, Text } from "@obvio/app";
import { Grid, GridItem } from "@obvio/ui";

import type { ReactElement } from "react";

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const TextWithSpan = styled(Text) <{ $full?: boolean }>`
  ${(_, { $full }) => ($full ? "grid-column: 1 / -1;" : "")}
`;

const Item = styled(GridItem)`
  border-left: 1px solid ${(theme) => theme.stroke};
  padding-left: ${(theme) => theme.spacing.medium};
  min-height: 10rem;
  > div {
    height: 100%;
  }
`;

const BottomText = styled(Text)`
  display: flex;
  align-items: flex-end;
`;

type Tile = {
  data: {
    text1?: string;
    text2?: string;
    text3?: string;
  };
  title: string;
};

type TilesProps = {
  tiles: Tile[];
};
export function Tiles({ tiles }: TilesProps): ReactElement {
  return (
    <Wrap templateColumns="repeat(3,  auto)" templateRows="10rem" gap="large">
      {tiles.map((tile) => (
        <Item key={tile.title} noCenter>
          <Grid templateColumns="1fr 1fr" templateRows="1fr 1fr">
            <TextWithSpan tag="span" as="h3" $full={!tile.data.text1}>
              {tile.title}
            </TextWithSpan>
            {tile.data.text1 && <Text>{tile.data.text1}</Text>}
            {tile.data.text2 ? (
              <BottomText>{tile.data.text2}</BottomText>
            ) : (
              <div />
            )}
            {tile.data.text3 ? (
              <BottomText>{tile.data.text3}</BottomText>
            ) : (
              <div />
            )}
          </Grid>
        </Item>
      ))}
    </Wrap>
  );
}
